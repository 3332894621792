/* A komponens iterál a "images" tömbön, és mindegyik képhez létrehoz egy div elemet, amelyben szerepel a kép és a hozzá tartozó szöveg. Mindegyik képre rákattintva a felhasználót átirányítja a "/galeria" oldalra, és az adott kép szűrési értékét (filter) állapotként átadja, amelyet később fel lehet használni a képgaléria szűrésére. */

import classes from "./OptionalPhotoshoots.module.css";
import portreMobile from "../../../Assets/OptionalPhotoShoots/image5.jpg";
import portre from "../../../Assets/OptionalPhotoShoots/portraitGroup.jpg";
import weddingsMobile from "../../../Assets/OptionalPhotoShoots/image7.jpg";
import weddings from "../../../Assets/OptionalPhotoShoots/weddingGroup.jpg";
import sportMobile from "../../../Assets/OptionalPhotoShoots/image12.jpg";
import sport from "../../../Assets/OptionalPhotoShoots/sportGroup.jpg";
import familyMobile from "../../../Assets/OptionalPhotoShoots/image.jpg";
import family from "../../../Assets/OptionalPhotoShoots/familyGroup.jpg";
import event from "../../../Assets/OptionalPhotoShoots/eventGroup.jpg";
import eventMobile from "../../../Assets/OptionalPhotoShoots/image34.jpg";
import budoirMobile from "../../../Assets/OptionalPhotoShoots/image1.jpg";
import budoir from "../../../Assets/OptionalPhotoShoots/boudoirGroup.jpg";
import petMobile from "../../../Assets/OptionalPhotoShoots/pet2.jpg";
import pet from "../../../Assets/OptionalPhotoShoots/petSolo.jpg";
import maternity from "../../../Assets/OptionalPhotoShoots/maternityGroup.jpg";
import maternityMobile from "../../../Assets/OptionalPhotoShoots/maternityMobile.jpg";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

//A kezdőképernyőn az "Irány a galéria rész"

const OptionalPhotoshoots = () => {
  const navigate = useNavigate();

  const [isMobileView, setIsMobileView] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize(); // Az alapértelmezett érték beállítása

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = [
    {
      id: 1,
      src: isMobileView ? portreMobile : portre,
      alt: "Image 1",
      text: "Portré fotózás",
      value: "portrait",
      filter: "portrait",
    },
    {
      id: 2,
      src: isMobileView ? weddingsMobile : weddings,
      alt: "Image 2",
      text: "Esküvői fotózás",
      value: "wedding",
      filter: "wedding",
    },
    {
      id: 3,
      src: isMobileView ? maternityMobile : maternity,
      alt: "Image 2",
      text: "Kismama fotózás",
      value: "maternity",
      filter: "maternity",
    },
    {
      id: 4,
      src: isMobileView ? budoirMobile : budoir,
      alt: "Image 3",
      text: "Boudoir fotózás",
      value: "budoir",
      filter: "budoir",
    },
    {
      id: 5,
      src: isMobileView ? sportMobile : sport,
      alt: "Image 4",
      text: "Sport fotózás",
      value: "sport",
      filter: "sport",
    },
    {
      id: 6,
      src: isMobileView ? familyMobile : family,
      alt: "Image 5",
      text: "Családi fotózás",
      value: "family",
      filter: "family",
    },
    {
      id: 7,
      src: isMobileView ? eventMobile : event,
      alt: "Image 6",
      text: "Rendezvény fotózás",
      value: "event",
      filter: "event",
    },
    {
      id: 8,
      src: isMobileView ? pet : petMobile,
      alt: "pet2",
      text: "Házi kedvencek fotózás",
      value: "pet",
      filter: "pet",
    },
  ];

  const onImageClickHandler = (image) => {
    console.log(image);

    navigate("/galeria", { state: { filter: image.filter } });
  };
  return (
    <div className={classes.container}>
      {images.map((image) => (
        <div
          key={image.id}
          className={classes.box}
          onClick={() => onImageClickHandler(image)}
        >
          <img src={image.src} alt={image.alt} />
          <div className={classes.text}>{image.text}</div>
        </div>
      ))}
    </div>
  );
};

export default OptionalPhotoshoots;
