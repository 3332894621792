import styles from "./PriceCard.module.css";
import { NavLink } from "react-router-dom";
import mini from "../../Assets/ChristmasPhotography/miniCsomag.jpg";
import basic from "../../Assets/ChristmasPhotography/alapCsomag.jpg";
import standard from "../../Assets/ChristmasPhotography/normalCsomag.jpg";
import premium from "../../Assets/ChristmasPhotography/premiumCsomag2.jpg";
import CartContext from "../Store/cart-context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const prices = [
  {
    img: mini,
    title: "Mini csomag",
    value: "Karácsonyi fotózás - Mini csomag - 22.000 Ft",
    titleClass: "bronze",
    duration: "1 óra fotózás",
    description: [
      "5 retusált kép digitálisan",
      "További képek: 1.500 Ft/db",
      "Az ár tartalmazza a stúdió bérlési árát is",
      "Igény esetén sminkest ajánlok, amit az ár nem tartalmaz",
      "Igény esetén prémium minőségű vászonkép, amit az ár nem tartalmaz",
      "Ajándékutalványként is megvásárolható",
      "A megadott árak Budapest és 50 km-es vonzáskörzetében érvényesek, további árak megbeszélés szerint",
    ],
    price: "22.000 Ft",
  },
  {
    img: basic,
    title: "Alap csomag",
    value: "Karácsonyi fotózás - Alap csomag - 30.000 Ft",
    titleClass: "bronze",
    duration: "1 óra fotózás",
    description: [
      "10 retusált kép digitálisan",
      "További képek: 1.500 Ft/db",
      "Az ár tartalmazza a stúdió bérlési árát is",
      "Ajándék 1 db kép előhívása, labor által",
      "Igény esetén sminkest ajánlok, amit az ár nem tartalmaz",
      "Igény esetén prémium minőségű vászonkép, amit az ár nem tartalmaz",
      "Ajándékutalványként is megvásárolható",
      "A megadott árak Budapest és 50 km-es vonzáskörzetében érvényesek, további árak megbeszélés szerint",
    ],
    price: "30.000 Ft",
  },
  {
    img: standard,
    title: "Normál csomag",
    value: "Karácsonyi fotózás - Normál csomag - 40.000 Ft",
    titleClass: "silver",
    duration: "1 óra fotózás",
    description: [
      "20 retusált kép digitálisan",
      "További képek: 1.500 Ft/db",
      "Az ár tartalmazza a stúdió bérlési árát is",
      "Ajándék 2 db kép előhívása, labor által",
      "Igény esetén sminkest ajánlok, amit az ár nem tartalmaz",
      "Igény esetén prémium minőségű vászonkép, amit az ár nem tartalmaz",
      "Ajándékutalványként is megvásárolható",
      "A megadott árak Budapest és 50 km-es vonzáskörzetében érvényesek, további árak megbeszélés szerint",
    ],
    price: "40.000 Ft",
  },
  {
    img: premium,
    title: "Prémium csomag",
    value: "Karácsonyi fotózás - Prémium csomag - 50.000 Ft",
    titleClass: "gold",
    duration: "2 óra fotózás",
    description: [
      "25 retusált kép digitálisan",
      "További képek: 1.500 Ft/db",
      "Az ár tartalmazza a stúdió bérlési árát is",
      "Ajándék 3 db kép előhívása, labor által",
      "Igény esetén sminkest ajánlok, amit az ár nem tartalmaz",
      "Igény esetén prémium minőségű vászonkép, amit az ár nem tartalmaz",
      "Ajándékutalványként is megvásárolható",
      "A megadott árak Budapest és 50 km-es vonzáskörzetében érvényesek, további árak megbeszélés szerint",
    ],
    price: "50.000 Ft",
  },
];

const ChristmasCard = () => {
  const cartCtx = useContext(CartContext);
  const navigate = useNavigate();

  const photoPackageValue = (value) => {
    cartCtx.setphotoPackage(value);
    navigate("/kapcsolat", { state: { value: value } });
  };

  return (
    <div>
      <h1 className={styles.title}>Karácsonyi fotózás</h1>
      <hr className={styles.underline} />
      <div className={styles.box}>
        <section className={styles.articles}>
          {prices.map((price, index) => (
            <article key={index} onClick={() => photoPackageValue(price.value)}>
              <div className={styles["article-wrapper"]}>
                <figure>
                  <img src={price.img} alt={price.title} />
                </figure>
                <div className={styles["article-body"]}>
                  <h2 className={styles[price.titleClass.toLowerCase()]}>
                    {price.title}
                  </h2>
                  <ul>
                    <li>{price.duration}</li>
                    {price.description.map((desc, index) => (
                      <li key={index}>{desc}</li>
                    ))}
                  </ul>
                  <h3 className={styles.price}>Ár: {price.price}</h3>
                  <NavLink className={styles["read-more"]}>
                    Időpont kérés{" "}
                    <span className={styles["sr-only"]}>
                      about this is some title
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.icon}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </NavLink>
                </div>
              </div>
            </article>
          ))}
        </section>
      </div>
    </div>
  );
};

export default ChristmasCard;
